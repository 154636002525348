export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127')
];

export const server_loads = [0];

export const dictionary = {
		"/": [9],
		"/actions/karma": [11],
		"/auth": [12],
		"/bloomerinvite": [13],
		"/bloomers-without-borders": [14],
		"/bounty": [15],
		"/bounty/claim/[claimId]": [16],
		"/chat": [17],
		"/chat/all": [18],
		"/chat/creatives": [19],
		"/chat/mybloom": [20],
		"/chat/water": [21],
		"/chat/water/aridLandscapes": [22],
		"/communityposts": [23],
		"/create/[type]": [24],
		"/donate": [25],
		"/edit/[slug]": [26],
		"/engage/communityCalls": [27],
		"/engage/contributors": [28],
		"/engage/donate": [29],
		"/engage/localBlooms": [30],
		"/engage/privacy": [31],
		"/engage/projects": [32],
		"/grants/distribution": [33,[2]],
		"/grants/roles": [34,[2]],
		"/grants/verify": [35,[2]],
		"/grants/verify/[slug]": [36,[2]],
		"/home": [37],
		"/joinABloom": [58],
		"/joinold": [59],
		"/join": [38],
		"/join/bloomers-without-borders": [39],
		"/join/localbloom": [40],
		"/join/localmember": [41],
		"/join/localmember/login": [42],
		"/join/newjoin": [43],
		"/join/register": [44],
		"/join/register/bloomerswithoutborders": [45],
		"/join/register/bloomerswithoutborders/apply": [46],
		"/join/register/helper": [47],
		"/join/register/joinlocal": [48],
		"/join/register/joinlocal/apply": [49],
		"/join/register/location": [50],
		"/join/register/onlinecontributor": [51],
		"/join/register/onlinecontributor/apply": [52],
		"/join/register/orientation": [53],
		"/join/register/schedule": [54],
		"/join/register/selectpath": [55],
		"/join/register/startlocalhub": [56],
		"/join/register/you": [57],
		"/learn/faq": [60,[3]],
		"/learn/faq/actionPosts": [61,[3]],
		"/learn/faq/businessAgreements": [62,[3]],
		"/learn/faq/localmembers": [63,[3]],
		"/learn/faq/membership": [64,[3]],
		"/learn/syllabus": [65,[4]],
		"/learn/syllabus/art": [66,[4]],
		"/learn/syllabus/economy": [67,[4]],
		"/learn/syllabus/intro": [68,[4]],
		"/learn/syllabus/nature": [69,[4]],
		"/learn/syllabus/tech": [70,[4]],
		"/learn/templates": [71,[5]],
		"/learn/templates/beautifyCommons": [72,[5]],
		"/learn/templates/communitygarden": [73,[5]],
		"/learn/templates/conflictResolution": [74,[5]],
		"/learn/templates/createActionTemplate": [75,[5]],
		"/learn/templates/cropswap": [76,[5]],
		"/learn/templates/csaa": [77,[5]],
		"/learn/templates/emailList": [78,[5]],
		"/learn/templates/eventFollowup": [79,[5]],
		"/learn/templates/eventReminder": [80,[5]],
		"/learn/templates/farmlandcommons": [81,[5]],
		"/learn/templates/firstEmail": [82,[5]],
		"/learn/templates/heartOfBloom": [83,[5]],
		"/learn/templates/kickoff": [84,[5]],
		"/learn/templates/legacy": [85,[5]],
		"/learn/templates/longevity": [86,[5]],
		"/learn/templates/mediaTeam": [87,[5]],
		"/learn/templates/meditations": [88,[5]],
		"/learn/templates/mission": [89,[5]],
		"/learn/templates/proposalTemplate": [90,[5]],
		"/learn/templates/roles": [91,[5]],
		"/learn/templates/thinktank": [92,[5]],
		"/learn/templates/urbanfarm": [93,[5]],
		"/learn/templates/vetting": [94,[5]],
		"/learn/whitepaper": [95],
		"/learn/whitepaper/business": [96],
		"/learn/whitepaper/economicprotocol": [97],
		"/learn/whitepaper/governance": [98],
		"/learn/whitepaper/governance/research": [99],
		"/localpage/new": [101],
		"/localpage/[location]": [100],
		"/marketplace": [102],
		"/member": [103],
		"/member/begin": [106],
		"/member/blogs": [107],
		"/member/bounty": [108],
		"/member/bounty/scratch": [109],
		"/member/dms": [110],
		"/member/dms/[slug]": [111],
		"/member/localactionrewards": [112],
		"/member/localactionrewards/impactreport": [113],
		"/member/localactionrewards/localmembers": [114],
		"/member/nobloomyet": [115],
		"/member/notifications": [116],
		"/member/viewGifts": [117],
		"/member/viewGifts/localbloom": [118],
		"/member/viewSkills": [119],
		"/member/voting/features": [120],
		"/member/[username]": [104,[6]],
		"/member/[username]/profile": [105,[6,7]],
		"/profile": [121,[8]],
		"/startLocal": [122],
		"/team": [123],
		"/view": [124],
		"/view/[slug]": [125],
		"/vision": [126],
		"/wallet": [127],
		"/[location]": [10]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';